import { Grid, FormControl, InputLabel, MenuItem } from "@mui/material";
import {
    StyledSectionTitle,
    StyledSectionContainerGrid,
    StyledSectionInputGroupGrid,
    StyledSectionMenuItem, StyledSectionTitleMargin,
} from "./styles";

import { useGetETAQuery } from "../../../sources/queries";

import TextField from "../../mui-components/TextField";
import Select from "../../mui-components/Select";
import {usePermissionContext} from "../../../context/PermissionContext";

export const DeliveryAreaSection = ({ isDisabled }) => {
  const {hasDeliveryAreaDeliveryFeePermission } = usePermissionContext();

  const { data: eta, isLoading } = useGetETAQuery();
  if (isLoading || !eta?.etaValues) return null;
  return (
    <>
      <StyledSectionTitle variant="h6">Delivery Area</StyledSectionTitle>
      <StyledSectionContainerGrid container spacing={4}>
        <StyledSectionInputGroupGrid item md={6} sm={12}>
          <TextField
            disabled={isDisabled}
            name="id"
            label="ID"
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          {!hasDeliveryAreaDeliveryFeePermission && (<TextField
            disabled={isDisabled}
            name="hubId"
            label="Hub id"
            variant="standard"
          />)}
          {!hasDeliveryAreaDeliveryFeePermission && (<TextField
            disabled={isDisabled}
            name="polygonGeometry"
            label="Polygon"
            variant="standard"
          />)}
          {!hasDeliveryAreaDeliveryFeePermission && (<Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel disabled={isDisabled} id="state-select-label">
                ETA Value
              </InputLabel>
              <Select
                disabled={isDisabled}
                name="etaValue.id"
                labelId="state-select-label"
                label="ETA Value"
              >
                <StyledSectionMenuItem value = {null}>
                  <em>None</em>
                </StyledSectionMenuItem>
                {eta?.etaValues?.map((etaValue) => (
                  <MenuItem key={etaValue.id} value={etaValue.id}>
                    {etaValue.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>)}

          {!hasDeliveryAreaDeliveryFeePermission && (<Grid item xs={8}>
            <FormControl variant="standard" fullWidth>
              <InputLabel disabled={isDisabled} id="state-select-label">
                ETA Reason
              </InputLabel>
              <Select
                disabled={isDisabled}
                name="etaReason.id"
                labelId="state-select-label"
                label="ETA Reason"
              >
                <StyledSectionMenuItem value = {null}>
                  <em>None</em>
                </StyledSectionMenuItem>
                {eta?.etaReasons?.map((etaReason) => (
                  <StyledSectionMenuItem
                    key={etaReason.id}
                    value={etaReason.id}
                  >
                    {etaReason.type} / {etaReason.comment}
                  </StyledSectionMenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>)}

          {!hasDeliveryAreaDeliveryFeePermission && (<TextField
            disabled={isDisabled}
            name="polygonName"
            label="Name"
            variant="standard"
          />)}
          {!hasDeliveryAreaDeliveryFeePermission && (<FormControl variant="standard">
            <InputLabel id="state-select-label">State</InputLabel>
            <Select
              disabled={isDisabled}
              name="state"
              labelId="state-select-label"
              label="State"
            >
              <MenuItem value={"OPEN"}>OPEN</MenuItem>
              <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
            </Select>
          </FormControl>)}

          {!hasDeliveryAreaDeliveryFeePermission && ( <StyledSectionTitleMargin variant="h6">Configuration Area</StyledSectionTitleMargin>)}
          {!hasDeliveryAreaDeliveryFeePermission && ( <TextField
                name="deliveryAreaConfiguration.lateDeliveryThreshold"
                label="Late delivery threshold"
                variant="standard"
            />)}

            {!hasDeliveryAreaDeliveryFeePermission && (<FormControl variant="standard">
                <InputLabel id="select-track-rider-label">Track Rider</InputLabel>
                <Select
                    disabled={isDisabled}
                    name="deliveryAreaConfiguration.trackRider"
                    labelId="select-track-rider-label"
                    label="Track rider"
                >
                    <MenuItem value={true}>TRUE</MenuItem>
                    <MenuItem value={false}>FALSE</MenuItem>
                </Select>
            </FormControl>)}

            {!hasDeliveryAreaDeliveryFeePermission && (<FormControl variant="standard">
                <InputLabel id="delivery-platform-select-label">Delivery platform</InputLabel>
                <Select
                    disabled={isDisabled}
                    name="deliveryAreaConfiguration.deliveryPlatform"
                    labelId="delivery-platform-select-label"
                    label="Delivery platform"
                >
                    <MenuItem value={"BRINGG"}>Bringg</MenuItem>
                    <MenuItem value={"RAIO"}>Raio</MenuItem>
                    <MenuItem value={"ZUBALE"}>Zubale proxy Bringg</MenuItem>
                    <MenuItem value={"PICKER_EXPRESS"}>Picker Express proxy Bringg</MenuItem>
                </Select>
            </FormControl>)}

            <StyledSectionTitleMargin variant="h6">Delivery Fee Configuration</StyledSectionTitleMargin>
          <TextField name="deliveryFeeAreaConfiguration.deliveryFeeName"
              label="Delivery fee name"
              variant="standard"/>

          <TextField name="deliveryFeeAreaConfiguration.deliveryFeeValue"
              label="Delivery fee value"
              variant="standard"
              type="number"/>



          <TextField name="deliveryFeeAreaConfiguration.deliveryStrikethroughPrice"
                     label="Delivery Strikethrough Price"
                     variant="standard"
                     type="number"/>

          <TextField name="deliveryFeeAreaConfiguration.movDelivery"
                     label="MOV Delivery"
                     variant="standard"
                     type="number"/>

          <TextField name="deliveryFeeAreaConfiguration.deliveryFeeAboveMOV"
                     label="Above MOV"
                     variant="standard"
                     type="number"/>

          <TextField name="deliveryFeeAreaConfiguration.movDeliveryIntervals"
                     label="MOV Delivery Intervals"
                     variant="standard"/>

          <TextField name="deliveryFeeAreaConfiguration.deliveryFeeAboveMOVIntervals"
                     label="Delivery fee above MOV intervals"
                     variant="standard"/>

        </StyledSectionInputGroupGrid>
      </StyledSectionContainerGrid>
    </>
  );
};
