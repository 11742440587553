import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";

import {
  StyledHubCard,
  StyledHubCardTitleGrid,
  StyledHubCardToggleButton,
  StyledHubCardTogglebuttonGroup,
  StyledHubCardSectionGrid,
} from "./styles";

import { usePermissionContext } from "../context/PermissionContext";
import { useNotificationContext } from "../context/NotificationContext";
import { useSwitchDeliveryAreaStateMutation } from "../sources/mutations";
import {useState} from "react";
import EditDeliveryAreaEtaModal from "./modals/EditDeliveryAreaEtaModal";
import EditDeliveryAreaServingHub from "./modals/EditDeliveryAreaServingHub";

export const DeliveryAreaInfo = ({
  onEditETA,
  onDeliveryAreaStateChange,
  ...props
}) => {
  const { notify } = useNotificationContext();
  const navigate = useNavigate();
  const { hasHubEditPermission, hasReadOnlyPermission,hasAdminOrSupervisorPermission, hasEditETAPermission, hasDeliveryAreaServingHubPermission,hasDeliveryAreaDeliveryFeePermission } = usePermissionContext();
  const [open, setOpen] = useState(false);
  const [hubInfo, setHubInfo] = useState({});
  const { data, mutate: updateDeliveryArea } =
    useSwitchDeliveryAreaStateMutation({
      id: props.id,
      onSuccess: ({ success }) => {
        if (success) {
          notify({
            severity: "success",
            message: "Delivery area state updated successfully !",
          });
        } else {
          notify({ severity: "error", message: "Something went wrong." });
        }
      },
      onError: () => {
        notify({ severity: "error", message: "Something went wrong." });
      },
    });
  const storeStatus = data?.data?.state;

  const changeDeliveryAreaState = (state) => {
    onDeliveryAreaStateChange(
      { triggers: props.triggers, id: props.id, state, polygonName: props.polygonName},
      updateDeliveryArea
    );
  };

  const onEtaEdit = (info) => {
    setOpen(true);
    setHubInfo(info);
  };

  const onServingHub = (info) => {
    setOpen(true);
    setHubInfo(info);
  }
  return (
    <>
      <StyledHubCard>
        <CardContent>
          <Grid container spacing={2}>
            <StyledHubCardTitleGrid item xs={8}>
              <Typography variant="h5" color="#053f6e">
                {props.polygonName}
              </Typography>
            </StyledHubCardTitleGrid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
              <CardActions>
                {!hasReadOnlyPermission && !hasDeliveryAreaServingHubPermission && !hasDeliveryAreaDeliveryFeePermission && (
                  <StyledHubCardTogglebuttonGroup
                    value={storeStatus || props.state}
                    exclusive
                    onChange={(_, value) => {
                      if (value) {
                        changeDeliveryAreaState(value);
                      }
                    }}
                  >
                    <StyledHubCardToggleButton color="primary" value="OPEN">
                      OPEN
                    </StyledHubCardToggleButton>
                    <StyledHubCardToggleButton color="error" value="CLOSED">
                      Closed
                    </StyledHubCardToggleButton>
                  </StyledHubCardTogglebuttonGroup>
                )}
                {(!hasReadOnlyPermission && hasAdminOrSupervisorPermission) && (
                    <Button
                      variant="contained"
                      disableRipple
                      size="large"
                      onClick={() => {
                        navigate(`/delivery-area/${props.id}`);
                      }}
                    >
                      Edit
                    </Button>
                  )}


                {(!hasReadOnlyPermission && !hasAdminOrSupervisorPermission && !hasDeliveryAreaServingHubPermission && !hasDeliveryAreaDeliveryFeePermission) && (
                    <Button
                      variant="contained"
                      disableRipple
                      size="large"
                      onClick={() => !hasHubEditPermission ? onEditETA(props) : onEtaEdit(props)}
                    >
                      Edit ETA
                    </Button>
                  )}
                {(!hasReadOnlyPermission && !hasAdminOrSupervisorPermission && hasDeliveryAreaServingHubPermission)  && (
                      <Button
                          variant="contained"
                          disableRipple
                          size="large"
                          onClick={() => !hasDeliveryAreaServingHubPermission ? onEditETA(props) : onEtaEdit(props)}
                      >
                        Edit hub
                      </Button>
                  )}

                {(!hasReadOnlyPermission && !hasAdminOrSupervisorPermission && hasDeliveryAreaDeliveryFeePermission)  && (
                      <Button
                          className="delivery-fees-btn"
                          variant="contained"
                          disableRipple
                          size="large"
                          onClick={() => {
                            navigate(`/delivery-area/${props.id}`);
                          }}
                      >
                        Edit Delivery Fees
                      </Button>
                  )}

                {hasReadOnlyPermission && (
                  <Button
                    variant="contained"
                    disableRipple
                    size="large"
                    onClick={() => {
                      navigate(`/delivery-area/${props.id}`);
                    }}
                  >
                    More Info
                  </Button>
                )}
              </CardActions>
            </Grid>

            <StyledHubCardSectionGrid item xs={3}>
              <Typography fontWeight="600" variant="subtitle1">
                Hub id
              </Typography>
              <Typography color="grey.700">{props.hubId}</Typography>

              <Typography fontWeight="600" variant="subtitle1">
                Internal id
              </Typography>
              <Typography color="grey.700">{props.id}</Typography>
            </StyledHubCardSectionGrid>
            <Grid item xs={3}>
              <Typography fontWeight="600" variant="subtitle1">
                ETA Value
              </Typography>
              <Typography color="grey.700">{props.etaValue?.value}</Typography>

              <Typography fontWeight="600" variant="subtitle1">
                ETA Reason
              </Typography>
              <Typography color="grey.700">
                {props.etaReason?.type} - {props.etaReason?.comment}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </StyledHubCard>
      {hasHubEditPermission && (
          <EditDeliveryAreaEtaModal open={open} setOpen={setOpen} hubInfo={hubInfo} />
      )}

      {hasDeliveryAreaServingHubPermission && (
          <EditDeliveryAreaServingHub open={open} setOpen={setOpen} hubInfo={hubInfo} />
      )}
    </>

  );
};
