import { Button } from "@mui/material";
import styled from "styled-components";

const StyledButton = styled(Button)`
  && {
    color: #063f71;
    background-color: #56e39f;
    font-family: Inter-Bold;
    margin-right: 16px;
    transition: 0.3s;

    &:hover {
      background-color: #36d794;
    }
  }
`;

const CustomButton = ({ children, ...props }) => {
  return (
    <StyledButton disableRipple {...props}>
      {children}
    </StyledButton>
  );
};

export default CustomButton;
