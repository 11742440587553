import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";

import {
  StyledSearchHeaderContainer,
  StyledSearchHeaderTitle,
  StyledSearchInput,
} from "./styles";

export const SearchHeader = ({ header, setSearch }) => {
  return (
    <StyledSearchHeaderContainer>
      <StyledSearchHeaderTitle variant="h4">{header}</StyledSearchHeaderTitle>
      <StyledSearchInput
        placeholder="Search by hub id"
        variant="standard"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setSearch(e.target.value);
          }
        }}
        onKeyUp={(e) => {
          if (e.key === "Backspace" && e.target.value === "") {
            setSearch("");
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </StyledSearchHeaderContainer>
  );
};
